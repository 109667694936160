import {QuestionsService} from "@/services/QuestionsService";


export default class QuestionsServiceEmbedded implements QuestionsService {

    private data: QuestionEntity[] = [
        {
            id: '1',
            content: 'Comment s\'appelle ton ours en peluche ?'
        },
        {
            id: '2',
            content: 'Je suis quelque chose qui t\'appartient mais que les gens utilisent plus que toi, qui suis-je ?'
        },
        {
            id: '3',
            content: 'Tu mesures ma vie en heures et je te sers en expirant. Je suis rapide quand je suis mince et lente quand je suis grosse. Le vent est mon ennemi.'
        },
        {
            id: '4',
            content: 'Tourne-moi sur le côté, et je suis tout. Coupe-moi en deux et je ne suis rien. Que suis-je ?'
        },
        {
            id: '5',
            content: 'Qu\'est-ce que tu peux tenir dans ta main droite mais jamais dans ta main gauche ?'
        },
        {
            id: '6',
            content: 'Qu\'est-ce qui est aussi gros qu\'un éléphant, mais ne pèse rien du tout ?'
        }
    ]

    private map: Map<string, ResponseEntity> = new Map<string, ResponseEntity>([
        ['1', {
            id: '1',
            compliment: `C'était facile on va pas se mentir, du coup petit compliment : t'es gentille.` ,
            expected: 'jsp'
        }],
        ['2', {
            id: '1',
            compliment: 'Tu es plus lumineuse que le soleil',
            expected: 'prénom'
        }],
        ['3', {
            id: '1',
            compliment: 'Tu arriverais à gagner le concours de Miss Univers en étant juste dans le public',
            expected: 'bougie'
        }],
        ['4', {
            id: '1',
            compliment: 'Tu es ravissante.. genre bcp.. vraiment bcp',
            expected: '8'
        }],
        ['5', {
            id: '1',
            compliment: 'Tes yeux sont tellement beaux que je n\'arriverais pas à me lasser de les regarder même si je faisais ça toute ma vie',
            expected: 'main gauche'
        }],
        ['6', {
            id: '1',
            compliment: 'Si tu travaillais dans la plus grande bijouterie du monde, tu serais quand même la chose la plus précieuse',
            expected: 'ombre'
        }]
    ]);

    async get(id: string): Promise<QuestionEntity> {
        const res = this.data.filter(value => value.id === id);
        if (res.length < 1) throw new Error(`Unable to find question with id = '${id}'.`);
        else return res[0];
    }

    async getAll(): Promise<QuestionEntity[]> {
        return Object.assign([], this.data);
    }

    async validate(id: string, response: string): Promise<string | false> {
        const responseEntity = this.map.get(id);
        if(responseEntity === undefined) throw new Error(`No response found for question with id = ${id}`);
        if(response.trim().toUpperCase().includes(responseEntity.expected.toUpperCase()))
            return responseEntity.compliment;
        else return false;
    }

}