
import {Options, Vue} from 'vue-class-component';
import {QuestionsService} from "@/services/QuestionsService";
import {Context} from "@/Context";

@Options({
    props: {
        uuid: String
    }
})
export default class QuestionForm extends Vue {

    private service: QuestionsService = Context.provideQuestionsService();
    private question: QuestionEntity | undefined;
    private uuid: string | undefined;

    private view = {
        title: 'Title',
        question: '',
        response: '',
        errorMsg: '',
        isSuccess: false,
        compliment: ''
    };

    async mounted(): Promise<void> {
        if (this.uuid !== undefined) {
            this.question = await this.service.get(this.uuid);
            this.view.title = `Question #${this.question.id}`;
            this.view.question = this.question.content;
        }
    }

    private async validate(): Promise<void> {
        if (this.uuid !== undefined) {

            try {
                const result = await this.service.validate(this.uuid, this.view.response);
                if (result === false)
                    this.showError('Mauvaise réponse :(');
                else
                    this.showSuccess(result);
            } catch (e) {
                console.log(e);
                this.showError('Error, see logs.');
            }
        }
    }

    private showSuccess(result: string) {
        this.view.isSuccess = true;
        this.view.compliment = result;
    }

    private showError(msg: string) {
        this.view.errorMsg = msg;
    }


}
