
import {Options, Vue} from 'vue-class-component';
import QuestionForm from "@/components/QuestionForm.vue";
import {QuestionsService} from "@/services/QuestionsService";
import {Context} from "@/Context";

@Options({
    components: {QuestionForm},
    props: {
        msg: String
    }
})
export default class HelloWorld extends Vue {
    private service: QuestionsService = Context.provideQuestionsService();
    private questions: QuestionEntity[] = [];


    async mounted(): Promise<void> {
        this.questions = await this.service.getAll();
    }


}
