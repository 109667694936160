import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionForm = _resolveComponent("QuestionForm")!

  return (_openBlock(), _createElementBlock("main", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (q) => {
      return (_openBlock(), _createBlock(_component_QuestionForm, {
        uuid: q.id
      }, null, 8, ["uuid"]))
    }), 256))
  ]))
}