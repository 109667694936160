import {QuestionsService} from "@/services/QuestionsService";
import QuestionsServiceEmbedded from "@/services/QuestionsServiceEmbedded";


export abstract class Context {

    private static questionsService : QuestionsService | undefined;

    public static provideQuestionsService() : QuestionsService {
        if(Context.questionsService === undefined)
            Context.questionsService = new QuestionsServiceEmbedded();
        return Context.questionsService;
    }

}